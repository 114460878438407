import { useTranslation } from "react-i18next";
import FormIput from "../../components/FormInput";
import Animation from "../../animation/Animation";
import useFormParts from "../../data/useFormParts";
import SuccessNotification from "../../components/Notification";
import useFormRequest from "../../data/useFormRequest";

export default function ContactUs() {
  const { t } = useTranslation();
  const formParts = useFormParts();
  const FORM_ENDPOINT = "https://old.webbydevs.com/sendMail.php";

  const { handleSubmit, status } = useFormRequest();

  const renderNotification = () => {
    if (status === "success") {
      return <SuccessNotification status={status} />;
    }
    return null;
  };

  return (
    <section>
      {renderNotification()}
      <section id="contact_us">
        <div className="flex flex-row justify-center mt-[50px] gap-x-5 container px-10 mx-auto my-16">
          <div className="lg:basis-1/2 grow">
            <form
              noValidate
              className="group h-full flex flex-col"
              action={FORM_ENDPOINT}
              onSubmit={handleSubmit}
              method="POST"
            >
              <h2 className="text-smh2 md:text-h2size header font-bold">
                <Animation direction="left">{t("get_offer")}</Animation>
              </h2>
              <div className="text-smpdefault paragraph md:text-pdefault font-thin">
                <Animation direction="left">{t("reply_speed")}</Animation>
              </div>
              {formParts.map((data, i) => {
                return (
                  <Animation direction="left" key={i}>
                    <FormIput
                      label={data.label}
                      id={data.id}
                      placeholder={data.placeholder}
                      pattern={data.pattern}
                      type={data.type}
                      errormessage={data.errormessage}
                      inputmode={data.inputmode}
                    />
                  </Animation>
                );
              })}
              <div className="flex flex-col mt-3 grow">
                <Animation direction="left">
                  <label className="text-smplarge md:text-plarge">
                    {t("message")}
                  </label>
                </Animation>
                <label htmlFor="texta" className="flex flex-col grow">
                  <Animation className="flex flex-row grow" direction="left">
                    <textarea
                      name="texta"
                      className="peer rounded-2xl border-0 bg-p50 tablet:h-[200px] h-[100%] sm:grow placeholder-p300 w-full paragraph"
                      required
                      placeholder={t("message_placeholder")}
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    ></textarea>
                  </Animation>
                  <span className="mt-2 hidden text-sm text-w300 peer-[&:not(:placeholder-shown):not(:focus):invalid]:block paragraph">
                    {t("form_error")}
                  </span>
                </label>
              </div>
              <Animation direction="left">
                <button
                  type="submit"
                  className="w-full mt-4 px-7 py-2 text-n0 text-smplarge md:text-plarge rounded-2xl paragraph gradient group-invalid:pointer-events-none group-invalid:opacity-30"
                >
                  {t("get_offer")}
                </button>
              </Animation>
              <div className="text-smpdefault font-thin paragraph my-4 max-w-3xl">
                <Animation direction="left">
                  {t("accept_privacy_policy_part_1")}
                  <a
                    href={process.env.PUBLIC_URL + "/privacy"}
                    className="text-base hover:underline"
                  >
                    {t("privacy_policy")}
                  </a>
                  {t("accept_privacy_policy_part_2")}
                </Animation>
              </div>
            </form>
          </div>
          <div className="hidden lg:flex">
            <Animation direction="right">
              <img
                src={process.env.PUBLIC_URL + "/images/ContactUsImg.webp"}
                alt="People drinking coffe in fron of a laptop discussing business"
                className="max-h-[90vh]"
              />
            </Animation>
          </div>
        </div>
      </section>
    </section>
  );
}
