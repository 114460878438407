import React from "react";

const structuredData = {
  "@context": "https://schema.org/",
  "@type": "WebSite",
  headline:
    "Webby - Egyedileg tervezett weboldalak, webalklamazások és egyéb digitális megoldások",
  description:
    "Csapatunk egyedileg tervezett weboldalakat, webalkalmazásokat és egyéb digitális megoldásokat nyújt az Ön vagy vállalkozása számára.",
  image: "https://old.webbydevs.com/images/logo.png",
  keywords: ["weboldal", "honlap", "készítés"],
  publisher: {
    "@type": "Organization",
    name: "Webby",
    logo: "https://old.webbydevs.com/images/logo.png",
  },
  article: {
    "@type": "Article",
    datePublished: "2023-12-21T19:48:11.340Z",
    author: {
      "@type": "Person",
      name: "Webby",
    },
  },
  organization: {
    "@type": "Organization",
    url: "https://webbydevs.com",
    logo: "https://old.webbydevs.com/images/logo.png",
    contactPoint: {
      "@type": "ContactPoint",
      email: "info@webbydevs.com",
    },
  },
  brand: {
    "@type": "Brand",
    name: "Webby",
    logo: "https://old.webbydevs.com/images/logo.png",
    description:
      "Webby - Egyedileg tervezett weboldalak, webalklamazások és egyéb digitális megoldások",
  },
  service: [
    {
      "@type": "Service",
      name: "Weboldal tervezés és fejlesztés",
      description:
        "Egyedi, modern és felhasználóbarát weboldalak tervezése és kivitelezése, melyek segítik ügyfeleinket a digitális térben való megjelenésben.",
      url: "https://old.webbydevs.com/",
    },
    {
      "@type": "Service",
      name: "Reszponzív design",
      description:
        "Biztosítjuk, hogy weboldalai tökéletesen megjelenjenek és működjenek mindenféle eszközön (számítógépek, laptopok, táblagépek, okostelefonok) a böngészőben.",
      url: "https://old.webbydevs.com/",
    },
    {
      "@type": "Service",
      name: "Keresőoptimalizálás",
      description:
        "Szolgáltatásainkkal segítjük weboldalait a keresőmotorokban való jobb rangsorolásban, növelve ezzel az online láthatóságát.",
      url: "https://old.webbydevs.com/",
    },
    {
      "@type": "Service",
      name: "Webalkalmazás fejlesztés",
      description:
        "Számos különböző webalkalmazás fejlesztését kínáljuk: készlet nyilvántartó rendszereket, ügyfélkezelő szoftvereket vagy akár az Ön igényeinek megfelelő megoldást is.",
      url: "https://old.webbydevs.com/",
    },
    {
      "@type": "Service",
      name: "UI/UX tervezés",
      description:
        "Képzett tervezőnk létrehozza a felhasználói felületet (UI) és segít a felhasználói élmény kialakításában (UX), hogy a látogatók könnyen és élvezettel használhassák a weboldalakat.",
      url: "https://old.webbydevs.com/",
    },
    {
      "@type": "Service",
      name: "Mentés és biztonsági intézkedések",
      description:
        "Gondoskodunk a rendszeres mentésekről, valamint implementáljuk a szükséges biztonsági intézkedéseket az adatok védelme érdekében.",
      url: "https://old.webbydevs.com/",
    },
  ],
};

const renderStructuredData = () => (
  <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
);

export default renderStructuredData;
