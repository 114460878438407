import { useTranslation } from "react-i18next";
import { EnvelopeIcon } from "@heroicons/react/24/solid";

export default function EmailContact(props) {
  const { t } = useTranslation();

  return (
    <div className={props.className}>
      <div className="flex flex-row mx-2 md:mx-10 my-1">
        <EnvelopeIcon className="h-5 md:h-6 mr-1 my-2" />
        <p className="paragraph align-middle mt-2 text-psm md:text-pdefault hover:underline">
          {t("webby_email")}
        </p>
      </div>
    </div>
  );
}
